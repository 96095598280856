import React, { FC } from 'react';
import s from './ModulesMenu.module.scss'
import arrow from '../../../assets/images/arrow-up-2.png'
import { NavLink } from 'react-router-dom';

const ModulesMenu: FC = () => {
    return (
        <div className={s.dropdown}>
            <button className={s.btn}>
                Модули
                <img src={arrow} alt="arrow" />
            </button>
            <div className={s.dropdown_menu}>
                <NavLink
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={'fvdf'}>Модуль 1</NavLink>
                <NavLink
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={'vdf'}>Модуль 2</NavLink>
                <NavLink
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={''}>Модуль 3</NavLink>
            </div>
        </div>
    );
};

export default ModulesMenu;