import React, { FC } from 'react';
import s from './Places.module.scss'

const Places: FC = () => {
    return (
        <section className={`${s.places} container`}>
            <div className={s.wrapper}>
                <div className={s.card}></div>
                <div className={s.card}></div>
                <div className={s.card}></div>
                <div className={s.card}></div>
            </div>
        </section>
    );
};

export default Places;