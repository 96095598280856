import React, { FC, useState } from 'react';
import s from './ShowFeedback.module.scss'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'
import FeedbackForm from './FeedbackForm/FeedbackForm';
import ModalHoc from '../ModalHoc/ModalHoc';

interface ShowFeedbackProps {
    children: React.ReactNode
}

const ShowFeedback: FC<ShowFeedbackProps> = ({ children }) => {
    const [show, setShow] = useState(false)

    const hide = () => {
        setShow(false)
    }

    return (
        <>
            <motion.button
                onClick={() => setShow(true)}
                className={s.subscription}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileTap={{ scale: 0.6 }}
                transition={{ type: "spring", stiffness: 600, damping: 9, duration: 0.8, }}
            >{children}</motion.button>
            {
                show &&
                <ModalHoc show={show} hide={hide}>
                    <FeedbackForm hide={hide} />
                </ModalHoc>
            }
        </>
    );
};

export default ShowFeedback;