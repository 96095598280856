import React, { FC } from 'react';
import s from './InfoColumn.module.scss'
import checkMark from '../images/checkMark.svg'

const InfoColumn: FC = () => {

    const textArr = [
        {
            description: "60 + видеоуроков",
            img: checkMark
        },
        {
            description: "20 тестовых заданий",
            img: checkMark
        },
        {
            description: '7 домашних заданий',
            img: checkMark
        },
        {
            description: '3 онлайн-вебинара ежемесячно',
            img: checkMark
        },
        {
            description: 'Дополнительные материалы',
            img: checkMark
        },
        {
            description: "Возможность задать вопросы ментору в кабинете",
            img: checkMark
        },
        {
            description: "Написание и проверка торгового алгоритма",
            img: checkMark
        },
    ]
    // console.log(textArr);


    return (
        <div className={s.info_block}>
            <h2 className={s.title}>
                Трейдинг Осознание - возможность пройти
                путь за короткий срок, который самостоятельно занял
                бы до 5 лет.
            </h2>

            <div className={s.modules}>
                <div className={s.module}>

                    <div className={s.module_num}>
                        <p>Модуль 1</p>
                    </div>

                    <h2>Первые шаги</h2>

                    <ul className={s.list}>
                        {textArr.map((item) => (
                            <li className={s.list_item}>
                                <img src={item.img} alt="checkMark" />
                                <p>{item.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default InfoColumn;