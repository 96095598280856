import React, { FC, useState } from 'react';
import s from './AboutUS.module.scss'
import poster from '../../../../assets/images/poster.png'
import play_btn from '../../../../assets/images/play.png'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { Element } from 'react-scroll';
import { useAppSelector } from '../../../../redux/hooks';
import ModalHoc from '../../../../Components/ModalHoc/ModalHoc';
import { backUrl } from '../../../../utils';
import ShowFeedback from '../../../../Components/ShowFeedback/ShowFeedback';

const AboutUS: FC = () => {
    const [show, setShow] = useState(false)
    const [showFeedback, setShowFeedBack] = useState(false)
    const { info } = useAppSelector(state => state.info)

    const handleClose = () => {
        setShow(false)
    }

    const hideFeedback = () => {
        setShowFeedBack(false)
    }

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <>
            <Element name='about_us'>
                <section className={`container ${s.wrapper}`}>
                    <motion.div
                        onClick={() => setShow(true)}
                        ref={ref}
                        initial={{ opacity: 0, x: -150 }}
                        animate={inView ? { opacity: 1, x: 0 } : {}}
                        transition={{ type: 'keyframes', stiffness: 300, duration: 0.8 }}
                        className={s.img_wrapper}
                    >
                        <img className={s.poster} src={poster} alt="poster" />
                        <img className={s.play} src={play_btn} alt="play button" />
                    </motion.div>
                    <div className={s.info_wrapper}>
                        <motion.h2
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8 }}
                            className={s.title}>О нас</motion.h2>
                        <motion.p
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8, delay: 0.3 }}
                            className={s.description}>
                            {info?.about_description}
                        </motion.p>
                        <ShowFeedback>Записаться на курс</ShowFeedback>
                    </div>
                </section>
            </Element>
            {
                info && show &&
                <ModalHoc hide={handleClose} show={show} >
                    <video
                        controls
                        controlsList="nodownload"
                        src={backUrl + info?.about_video}
                        poster={backUrl + info?.about_video}>
                        Sorry, your browser doesn't support embedded videos
                    </video>
                </ModalHoc>
            }
        </>
    );
};

export default AboutUS;