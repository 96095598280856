export const setLS = (key: string, item: string) => {
    localStorage.setItem(key, JSON.stringify(item))
}

export const getLS = (key: string) => {
    const data = localStorage.getItem(key)
    if (data) {
        return JSON.parse(data)
    }
}

export const removeLS = (key: string) => {
    localStorage.removeItem(key)
}