import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../../Pages/Home/Home';
import Program from '../../Pages/Program/Program';

import { useAppDispatch } from '../../redux/hooks';
import { fetchByAllInfo } from '../../redux/slices/infoSlice';

const Main: FC = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])

    return (
        <main>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/program' element={<Program />} />
            </Routes>
        </main>
    );
};

export default Main;