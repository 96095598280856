import React, { FC } from 'react';
import Hero from './Sections/Hero/Hero';
import AboutUS from './Sections/AboutUS/AboutUS';
import WhyWe from './Sections/WhyWe/WhyWe';
import { LayoutGroup } from 'framer-motion';
import Forex from './Sections/Forex/Forex';
import Places from './Sections/Places/Places';

const Home: FC = () => {
    return (
        <LayoutGroup id='home'>
            <Hero />
            <AboutUS />
            <WhyWe />
            <Forex />
            <Places />
        </LayoutGroup>
    );
};

export default Home;