import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ruNs1 from './locales/ru/translation.json';
import kgNs1 from './locales/kg/translation.json';

const resources = {
    ru: {
        translation: ruNs1
    },
    kg: {
        translation: kgNs1
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ru',
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['navigator', "localStorage", "cookie"],
            caches: ["localStorage", "cookie"],
        },
    });

export default i18n;