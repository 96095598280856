import React from 'react';
import s from './Forex.module.scss'
import { useAppSelector } from '../../../../redux/hooks';
import { backUrl } from '../../../../utils';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

const Forex = () => {
    const { info } = useAppSelector(state => state.info)
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <section className={`${s.wrapper} container`}>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, x: 150 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.2 }}
                className={s.forex} style={{ backgroundImage: `url(${backUrl + info?.forex_image})` }}>
                <div className={s.info}>
                    <motion.h2
                        initial={{ opacity: 0, y: 50 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.8, delay: 0.4 }}
                        className={s.title}>Станьте экспертом на рынке <span className={s.rotate}>FOREX</span></motion.h2>
                    <motion.p
                        initial={{ opacity: 0, y: 50 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.8, delay: 0.5 }}
                        className={s.description}>
                        {info?.forex_description}
                    </motion.p>
                </div>
            </motion.div>
        </section>
    );
};

export default Forex;