import { configureStore } from '@reduxjs/toolkit';
import homeSlice from './slices/homeSlice';
import infoSlice from './slices/infoSlice';

const store = configureStore({
    reducer: {
        home: homeSlice,
        info: infoSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;