import React, { FC } from 'react';
import s from './Contacts.module.scss'
import phone from '../../../assets/images/phone.svg'
import mail from '../../../assets/images/mail.svg'
import address from '../../../assets/images/adress.svg'
import wa from '../../../assets/images/whatsapp.svg'
import telegram from '../../../assets/images/telegram.svg'
import instagram from '../../../assets/images/Instagram.svg'
import { useAppSelector } from '../../../redux/hooks';

const Contacts: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <div className={s.wrapper}>
            <h2 className={s.title}>Контакты</h2>
            <div className={s.info}>
                <a href={`tel:${info?.phone_number}`} target='_blank' rel='noopener noreferrer'>
                    <img src={phone} alt="phone icon" />
                    {info?.phone_number}
                </a>
                <a href={`mailto:${info?.email}`} target='_blank' rel='noopener noreferrer'>
                    <img src={mail} alt="phone icon" />
                    {info?.email}
                </a>
                <a href={`https://2gis.kg/bishkek/search/${encodeURIComponent(`${info?.address}`)}`} target='_blank' rel='noopener noreferrer'>
                    <img src={address} alt="phone icon" />
                    {info?.address}
                </a>
            </div>
            <div className={s.social_wrapper}>
                <a href={info?.instagram} target='_blank' rel='noopener noreferrer'>
                    <img src={instagram} alt="instagram icon" />
                </a>
                <a href={`https://wa.me/${info?.whatsapp}`} target='_blank' rel='noopener noreferrer'>
                    <img src={wa} alt="whatsapp icon" />
                </a>
                <a href={info?.telegram} target='_blank' rel='noopener noreferrer'>
                    <img src={telegram} alt="telegram icon" />
                </a>
            </div>
        </div>
    );
};

export default Contacts;