import React, { FC } from 'react';
import s from './WhyWe.module.scss'
import giff from '../../../../assets/images/gifka.gif'
import bg1 from '../../../../assets/images/bg_fone1.png'
import bg2 from '../../../../assets/images/bg_fone2.jpg'
import bg3 from '../../../../assets/images/img37.jpg'
import { IWhyWe } from '../../../../redux/types';
import WhyCard from './WhyCard/WhyCard';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from '../../../../redux/hooks';
import ShowFeedback from '../../../../Components/ShowFeedback/ShowFeedback';

const arr: IWhyWe[] = [
    {
        id: 1,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg1
    },
    {
        id: 2,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg2
    },
    {
        id: 3,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg3
    },
    {
        id: 4,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg2
    },
    {
        id: 5,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg1
    },
    {
        id: 6,
        logo: giff,
        title: 'Овладей исскуством торговли!',
        description: 'Устали от бесполезных схем и банковских ботов, контролирующих мир финансов? Обучайтесь торговле с умом и интуицией вместе с нами!',
        bg_fone: bg2
    },
]

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
            duration: 0.3,
        }
    }
};

const WhyWe: FC = () => {
    const { info } = useAppSelector(state => state.info)

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <>
            {
                info?.why_we && info?.why_we.length > 0 &&
                <section className={`container ${s.wrapper}`}>
                    <h2 className={s.title}>Почему мы?</h2>
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : ''}
                        variants={container}
                        className={s.cards_wrapper}>
                        {
                            info?.why_we.map(el => <WhyCard key={el.id} {...el} />)
                        }
                    </motion.div>
                    <span className={s.btn_wrapper}>
                        <ShowFeedback>
                            Оформить подписку
                        </ShowFeedback>
                    </span>
                </section>
            }
        </>
    );
};

export default WhyWe;