import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IFeedbackUser, IInfo, INullable } from "../types"
import { itsAPI } from "../../axios"
import { setLS } from "../../LS"

type InfoState = {
    loading: boolean
    error: INullable<string>
    info: INullable<IInfo>
    success_modal: boolean
    feedback_error: INullable<string>
}

const initialState: InfoState = {
    error: null,
    loading: false,
    info: null,
    success_modal: false,
    feedback_error: null,
}

export const fetchByAllInfo = createAsyncThunk<IInfo, void, { rejectValue: string }>(
    'info/fetchByAllInfo',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.getAllInfo()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data as IInfo

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByNewFeedback = createAsyncThunk<void, IFeedbackUser, { rejectValue: string }>(
    'info/fetchByNewFeedback',
    async (userInfo, { rejectWithValue }) => {
        try {
            const res = await itsAPI.feedback(userInfo)
            if (res.status !== 201) {
                throw new Error('Server Error')
            }

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        setFeedbackError(state, action: PayloadAction<string | null>) {
            state.feedback_error = action.payload
        },
        closeSuccess(state) {
            state.success_modal = false
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByAllInfo.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByAllInfo.fulfilled, (state, action) => {
            state.info = action.payload
            state.loading = false
        })
        addCase(fetchByAllInfo.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //============================================================
        addCase(fetchByNewFeedback.pending, (state) => {
            state.loading = true
            state.feedback_error = null
        })
        addCase(fetchByNewFeedback.fulfilled, (state) => {
            setLS('lastSubmitTime', Date.now().toString());
            state.success_modal = true
            state.loading = false
        })
        addCase(fetchByNewFeedback.rejected, (state, action) => {
            if (action.payload) {
                state.feedback_error = 'Что-то пошло не так. Попробуйте позже!'
            }
            state.loading = false
        })
        //============================================================
    }
})

export const { setFeedbackError, closeSuccess } = infoSlice.actions

export default infoSlice.reducer