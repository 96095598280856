import React, { FC } from 'react';
import TradeInfoMain from './TradeInfoMain/TradeInfoMain';
import Mod1Card from './Mod1Card/Mod1Card';
import InfoColumn from './InfoColumn/InfoColumn';

const Program: FC = () => {
    return (
        <div className='container'>
            <TradeInfoMain />
            <Mod1Card />
            <InfoColumn />
        </div>
    );
};

export default Program;