import React, { FC } from 'react';
import ModulesMenu from '../Header/ModulesMenu/ModulesMenu';
import { NavLink, useNavigate } from 'react-router-dom';
import { scroller, Link as ScrollLink } from 'react-scroll';
import s from './Navigation.module.scss'

const Navigation: FC = () => {
    const navigate = useNavigate();

    const scrollToSection = (section: string) => {
        navigate('/');

        setTimeout(() => {
            scroller.scrollTo(section, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
            });
        }, 100);
    };

    return (
        <ul className={s.menu}>
            <li>
                <button className={s.menu_link}
                    onClick={() => scrollToSection('about_us')}>О нас</button>
            </li>
            <li>
                <NavLink to={'/program'}
                    className={({ isActive }) => isActive ? `${s.menu_link} ${s.active}` : s.menu_link}
                >Программа</NavLink>
            </li>
            <li>
                <button className={s.menu_link}
                    onClick={() => scrollToSection('mentors')}>Менторы</button>
            </li>
            <ModulesMenu />
            <li>
                <button className={s.menu_link}
                    onClick={() => scrollToSection('rewiews')}>Отзывы</button>
            </li>
            <li>
                <ScrollLink
                    activeClass={s.active}
                    className={s.menu_link}
                    to={"contacts"}
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                >Контакты</ScrollLink>
            </li>
        </ul>
    );
};

export default Navigation;