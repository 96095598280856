import { createSlice } from "@reduxjs/toolkit"

type HomeState = {}

const initialState: HomeState = {}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {

    }
})

export default homeSlice.reducer