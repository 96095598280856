import React, { useEffect, useState } from 'react';
import './App.scss'
import './i18n'
import { useTranslation } from 'react-i18next';
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';

const App = () => {
  const { t, i18n } = useTranslation()


  // const [isBlurred, setIsBlurred] = useState(false);

  // const showAlert = () => {
  //   alert('Запрещено делать скриншоты и записывать экран.');
  // }

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     setIsBlurred(document.hidden);
  //   };

  //   const handleFocusChange = () => {
  //     setIsBlurred(!document.hasFocus());
  //   };

  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     const forbiddenKeys = ['PrintScreen', 'F12', 'Control', 'Shift'];
  //     if (forbiddenKeys.includes(e.key)) {
  //       e.preventDefault();
  //       showAlert()
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   window.addEventListener('focus', handleFocusChange);
  //   window.addEventListener('blur', handleFocusChange);
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     window.removeEventListener('focus', handleFocusChange);
  //     window.removeEventListener('blur', handleFocusChange);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  // useEffect(() => {
  //   const checkClipboard = async () => {
  //     try {
  //       const clipboardItems = await navigator.clipboard.read();
  //       for (const item of clipboardItems) {
  //         if (item.types.includes('image/png')) {
  //           showAlert()
  //           setIsBlurred(true)
  //         } else {
  //           setIsBlurred(false)
  //         }
  //       }
  //     } catch (err) {
  //       console.error('Ошибка при чтении буфера обмена:', err);
  //     }
  //   };

  //   const interval = setInterval(checkClipboard, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div style={{
      // filter: isBlurred ? 'blur(10px) brightness(10%)' : 'none',
      // transition: 'filter 0.3s ease',
      // backgroundColor: isBlurred ? 'black' : 'transparent',
      // minHeight: '100vh'
    }}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default App;