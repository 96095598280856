import React, { FC } from 'react';
import s from './Mod1Card.module.scss'
import bg from '../images/mod1_bg.jpg'

const Mod1Card: FC = () => {
    return (
        <div className={s.card} style={{ background: `url(${bg}) center center / cover no-repeat` }} >
            <div className={s.info}>
                <div className={s.module_num}>
                    <p>Модуль 1</p>
                </div>
                <div className={s.mod_time}>
                    <p> <span>Продолжительность:</span> 1 месяц</p>
                </div>
            </div>

            <div className={s.first_steps}>
                <h2>Трейдинг Осознание: первые шаги</h2>
                <p>Обучение начинающих основам трейдинга, включая теорию рынка, анализ данных и психологию трейдинга
                    для развития осознанности на финансовых рынках.
                    В этом курсе вы получите знания и инструменты, необходимые для успешного старта.</p>
                <div className={s.btn_group}>
                    <button>Записаться на курс</button>
                    <button>Программа</button>
                </div>
            </div>
        </div>
    );
};

export default Mod1Card;