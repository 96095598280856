import React, { FC } from 'react';
import s from './TradeInfoMain.module.scss'
import module1 from '../images/module1_info.png'


const TradeInfoMain: FC = () => {
    return (
        <div className={s.main_block}>
            <div className={s.info}>
                <h2>“Трейдинг Осознание”</h2>
                <p>Состоит из 3 последовательных Модулей, чтобы вы могли постепенно,
                    шаг за шагом дойти до профессионального уровня в трейдинге,
                    сформировать собственную стратегию и развиваться дальше — вплоть до возможности
                    стать одним из менторов ITS.</p>
                <button>Подробнее</button>
            </div>

            <img src={module1} alt="module1" />
        </div>
    );
};

export default TradeInfoMain;