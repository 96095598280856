import React, { FC, useEffect } from 'react';
import s from './SuccessModal.module.scss'

interface SuccessModalProps {
    hide: () => void
}

const SuccessModal: FC<SuccessModalProps> = ({ hide }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            hide()
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [])


    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <h2 className={s.title}>Спасибо!</h2>
                <h3 className={s.subtitle}>Ваша заявка принята</h3>
                <p>С Вами свяжуться в ближайшее время!</p>
            </div>
        </div>
    );
};

export default SuccessModal;